import React, { useState } from "react";
import { Stack, Heading, HStack, Box, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Markup } from "interweave";
import { uniq, flatten } from "lodash";
import { Link } from "gatsby";

import Authors from "./Authors";
import TextBlock from "./TextBlock";
import Figure from "./Figure";
import References from "./References";
import Contributions from "./Contributions";
import History from "./History";
import ArticleMenu from "./ArticleMenu";
import Corrections from "./Corrections";
import ExtendedData from "./ExtendedData";
import Proteopedia from "./Proteopedia";
import CitedBy from "./CitedBy";

const initials = (name) => {
  const matches = name.match(/\b([A-Z])/g);
  return matches && matches.join("");
};

const authorsString = (authors) => {
  if (!authors || authors.length === 0) return "";

  if (authors.length > 8) {
    const lastAuthor = authors[authors.length - 1];
    return `${authors
      .slice(0, 6)
      .map((author) => `${author.lastName}, ${initials(author.firstName)}`)
      .join("; ")}; et al.; ${lastAuthor.lastName}, ${initials(
      lastAuthor.firstName
    )}`;
  }
  return authors
    .map((author) => {
      const firstInitials = initials(author.firstName);
      return firstInitials
        ? `${author.lastName}, ${firstInitials}`
        : `${author.firstName} ${author.lastName}`;
    })
    .join("; ");
};

const fundingString = (funding, awards) => {
  let fundingHtml = "";

  if (funding) fundingHtml = `<p>${funding}</p>`;

  if (awards && awards.length > 0) {
    fundingHtml = `${fundingHtml}`;

    awards.forEach(
      (award) =>
        (fundingHtml = `${fundingHtml}<p>Supported by ${award.funderName} ${award.awardId} to ${award.awardRecipient}.</p>`)
    );
  }

  return fundingHtml;
};

const Article = (props) => {
  const { article, speciesTaxonomy, citedBy } = props;

  const {
    doi,
    history,
    version,
    submissionTypes,
    id,
    integrations,
    dbReferenceId,
    pmcId,
    pmId,
    species,
    corrections,
    reviewers,
    proteopedia,
    reviewPanel,
    curators,
  } = article;

  const {
    title,
    authors,
    abstract,
    image,
    imageTitle,
    imageCaption,
    patternDescription,
    methods,
    reagents,
    references,
    acknowledgements,
    funding,
    extendedData,
    awards,
  } = version;

  const [showProteopedia, setShowProteopedia] = useState(false);
  const [isFigureOpen, setIsFigureOpen] = useState(false);

  const metadataMapper = { doi, pmId, pmcId, dbReferenceId };

  const transformSpecies = (species, speciesTaxonomy) =>
    species.map((s) => {
      const mod = speciesTaxonomy.find(
        (taxonomy) => taxonomy.value === s.toLowerCase()
      );
      if (!mod) return { modLink: "", mod: "" };
      const modLink = mod.linkVariable
        ? `${mod.modLink}${metadataMapper[mod.linkVariable]}`
        : mod.modLink;

      return { modLink, mod: mod.mod };
    });

  const year =
    history && history.published
      ? new Date(history.published).getFullYear()
      : "2022";
  const copyright = `\u00a9 ${year} by the authors. This is an open-access article distributed under the terms of the Creative Commons Attribution 4.0 International (CC BY 4.0) 
  License, which permits unrestricted use, distribution, and reproduction in any medium, provided the original author and source are credited.`;

  const reviewedBy = uniq(flatten(reviewers))
    .filter((r) => r)
    .join(", ");

  const curatedBy = uniq(flatten(curators))
    .filter((c) => c)
    .join(", ");

  const titleWithoutP = title.replace("<p>", "").replace("</p>", "");

  const citationString = `${authorsString(
    authors
  )} (${year}). ${titleWithoutP}. microPublication Biology. <a href="https://doi.org/${doi}" target="_blank" rel="noopener noreferrer">${doi}</a>.`;

  const extension = image.url.split(".").pop();
  const articleId =
    doi && doi.includes("/") && doi.split("/").pop().replace(/\./g, "-");
  const figure = `/static/figures/${articleId}.${extension}`;

  const extendedDataDoi = extendedData && extendedData.filter((e) => e.doi);

  const fundingHtml = fundingString(funding, awards);

  return (
    <HStack alignItems="top">
      <Box w={["12.5%", "12.5%", "12.5%", "25%"]} />
      <Stack paddingTop="100px" w={["75%", "75%", "75%", "50%"]}>
        <Heading fontFamily="Noto sans, sans-serif">
          <Markup content={title} />
        </Heading>
        <Authors authors={authors} />
        <Corrections corrections={corrections} />
        <ArticleMenu
          citation={citationString}
          submissionTypes={submissionTypes}
          title={title}
          articleId={id}
          versionId={version.id}
          doi={doi}
          pmcId={pmcId}
          pmId={pmId}
          species={transformSpecies(species, speciesTaxonomy)}
          showProteopediaButton={proteopedia}
          onClickShowProteopedia={() => setShowProteopedia(true)}
          citedBy={citedBy}
        />
        {proteopedia && (
          <Proteopedia
            proteopediaLink={proteopedia}
            showProteopedia={showProteopedia}
            setShowProteopedia={setShowProteopedia}
          />
        )}
        <TextBlock label="Abstract" text={abstract} isHtml />
        <Figure
          figure={figure}
          title={imageTitle}
          caption={imageCaption}
          isFigureOpen={isFigureOpen}
          setIsFigureOpen={setIsFigureOpen}
        />
        <TextBlock
          label="Description"
          text={patternDescription}
          setIsFigureOpen={setIsFigureOpen}
          isHtml
        />
        <TextBlock
          label="Methods"
          text={methods}
          setIsFigureOpen={setIsFigureOpen}
          isHtml
          doi={doi}
        />
        <TextBlock
          label="Reagents"
          setIsFigureOpen={setIsFigureOpen}
          text={reagents}
          isHtml
        />
        {extendedDataDoi && extendedDataDoi.length > 0 && (
          <ExtendedData extendedData={extendedDataDoi} />
        )}
        <TextBlock label="Acknowledgements" text={acknowledgements} isHtml />
        <TextBlock label="Funding" text={fundingHtml} isHtml />
        <Contributions authors={authors} />
        <TextBlock label="Reviewed By" text={reviewedBy} />
        {reviewPanel && (
          <>
            <Heading
              size="lg"
              fontWeight="normal"
              fontFamily="Noto sans, sans-serif"
            >
              Reviewed By
            </Heading>
            <u>
              <Link to="/review-panels">{reviewPanel}</Link>
            </u>
          </>
        )}
        <TextBlock label="Nomenclature Validated By" text={curatedBy} />
        {dbReferenceId && (
          <TextBlock text={`Database Reference ID: ${dbReferenceId}`} />
        )}
        <History history={history} />
        <References references={references} />
        <TextBlock label="Copyright" text={copyright} />
        <TextBlock label="Citation" text={citationString} isHtml />
        {pmcId && <TextBlock text={`PubMed Central: ${pmcId}`} />}
        {pmId && <TextBlock text={`PubMed: ${pmId}`} />}
        {citedBy && <CitedBy citedBy={citedBy} />}
      </Stack>
      {integrations.length > 0 && (
        <Box paddingTop="200px" w={["12.5%", "12.5%", "12.5%", "25%"]}>
          <Heading
            size="lg"
            fontWeight="normal"
            fontFamily="Noto sans, sans-serif"
          >
            Integrations
          </Heading>
          {integrations.map((integration) => (
            <Link to={`/journals/biology/${integration.articleId}`}>
              <Box
                borderWidth="1px"
                borderRadius="lg"
                boxShadow="md"
                align="top"
                _hover={{ border: "1px solid" }}
                margin="5px"
                padding="5px"
              >
                <Text fontFamily="Noto sans, sans-serif">
                  <Markup content={integration.title} />
                </Text>
              </Box>
            </Link>
          ))}
        </Box>
      )}
    </HStack>
  );
};

Article.propTypes = {
  article: PropTypes.shape({
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        authors: PropTypes.arrayOf(
          PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            credit: PropTypes.arrayOf(PropTypes.string),
            email: PropTypes.string,
            correspondingAuthor: PropTypes.bool,
            equalContribution: PropTypes.bool,
            affiliations: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        abstract: PropTypes.string,
        image: PropTypes.shape({ url: PropTypes.string }),
        imageTitle: PropTypes.string,
        imageCaption: PropTypes.string,
        description: PropTypes.string,
        methods: PropTypes.string,
        reagents: PropTypes.string,
        references: PropTypes.arrayOf(PropTypes.shape({})),
        acknowledgements: PropTypes.string,
        funding: PropTypes.string,
        reviewers: PropTypes.arrayOf(PropTypes.shape({})),
        extendedData: PropTypes.arrayOf(PropTypes.shape({})),
      })
    ),
    history: PropTypes.shape({}),
    doi: PropTypes.string,
    submissionTypes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    pmId: PropTypes.string,
    pmcId: PropTypes.string,
    species: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Article;
